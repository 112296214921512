<template>
  <div class="doctorInfo_container">
    <div class="doctorInfo_banner">
      <div class="doctorInfo_info">
        <div class="doctorInfo_info_left">
          <img
            class="doctorInfo_avatar"
            :src="require('@/assets/img/avatar.png')"
          />
        </div>
        <div class="doctorInfo_info_right">
          <div class="doctorInfo_info_right_top">
            <div>
              <div class="name">{{ data.name }}/{{ data.name_pinyin }}</div>
              <div class="department">
                {{ data.department }}
              </div>
            </div>
            <div
              style="
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: end;
              "
            >
              <CustomButton class="CustomButton" @click="goToPersonalInfo"
                >完善个人信息</CustomButton
              >
              <div
                style="font-size: 12px; color: red; white-space: nowrap"
                v-if="data.work_card_ocr_status === 0"
              >
                专家职称信息审核中
              </div>
              <div
                style="font-size: 12px; color: red; white-space: nowrap"
                v-if="data.work_card_ocr_status === 2"
              >
                专家职称信息失败
              </div>
            </div>
          </div>
          <div class="doctorInfo_info_right_bottom">
            <div class="hospital">
              <img :src="require('@/assets/img/hospital.png')" />{{
                data.hospital
              }}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="province_city">
              <img :src="require('@/assets/img/location.png')" />{{
                data.province
              }}&nbsp;&nbsp;&nbsp;&nbsp;{{ data.city }}
            </div>
          </div>
        </div>
      </div>
      <div class="doctorInfo_meetInfo">
        <span>
          总会议数：
          <span class="global-color"> {{ data.all_count }}</span></span
        >
        <span>
          已预约：
          <span class="global-color"> {{ data.booked_count }}</span></span
        >
        <span>
          已召开：
          <span class="global-color"> {{ data.convened_count }}</span></span
        >
      </div>
    </div>

    <div class="doctorInfo_main">
      <div class="title">会议列表</div>
      <div class="info_tips" v-if="data?.meet_list?.length == 0">暂无数据</div>
      <ItemScroll @pullingUp="pullingUp" v-else ref="ItemScroll">
        <div class="doctorInfo_main_block">
          <meetItem
            v-for="(item, index) in data.meet_list"
            :key="index"
            :item="item"
            :user_id="data.id"
            @goToMeet="goToMeet"
            >{{ index }}</meetItem
          >
        </div>
      </ItemScroll>
    </div>
    <div class="doctorInfo_foot">
      <div class="doctorInfo_foot_btn" @click="goToWeeklyHome">
        <img
          class="doctorInfo_foot_btn_icon"
          :src="require('@/assets/img/homing.png')"
          alt=""
        />
        <div>周刊首页</div>
      </div>
      <div class="doctorInfo_foot_btn" @click="outLogin">
        <img
          class="doctorInfo_foot_btn_icon"
          :src="require('@/assets/img/outLogin.png')"
          alt=""
        />
        <div>退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/unit/CustomButton";
import meetItem from "./meetItem.vue";
import ItemScroll from "../../unit/ItemScroll.vue";
import weixin from "@/common/weixin";
export default {
  name: "doctorInfo",
  components: { CustomButton, meetItem, ItemScroll },
  data() {
    return {
      data: {},
      search: {
        page: "",
        pagesize: "20",
      },
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      //salesUserHome
      let url = this.$tools.getURL(this.$urls.sale.userHome, {});
      await this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.ItemScroll.initScroll();
            }, 1000);
          });
        })
        .catch(() => {});

      // this.$axios.get(this)
    },
    goToMeet(item) {
      console.log("item :>> ", item);
      if (this.data.work_card_ocr_status === 0) {
        return this.$tips.error({
          text: "专家职称信息审核中，审核通过后方可进入会议",
        });
      }
      if (this.data.work_card_ocr_status === 2) {
        return this.$tips.error({
          text: "专家职称信息审核失败，审核通过后方可进入会议",
        });
      }
      if (!this.data.first_complete)
        return this.$tips.error({ text: "完善信息后，方可进入会议" });
      if (item.analysis_status == 0)
        return this.$tips.success({ text: "课件解析中，请稍后" });
      this.$tips.success({ text: "开会" });
      let start = this.$tools.getTime(item.meet_start_time) - 30 * 1000 * 60;
      let end = this.$tools.getTime(item.meet_start_time) + 30 * 1000 * 60;
      let newT = this.$tools.getTime(new Date());
      if (newT < start || newT > end)
        return this.$tips.error({
          text: "当前时间不在预定会议时间内，请修改会议时间后再开展会议！",
        });

      let content = {
        projectId: this.$config.projectId,
        openid: item.openid,
        meetId: item.id,
      };
      let url = this.$tools.getURL("/pages/user/bind", content);
      weixin.wx.miniProgram.redirectTo({ url });
    },
    pullingUp() {
      this.page += 1;
      this.loadData();
    },
    goToWeeklyHome() {
      this.$router.push({
        path: "/articleList",
      });
    },
    outLogin() {
      this.$store.dispatch("setItem", {
        userId: 0,
        userInfo: {},
      });
      this.$tools.setStorage("userLogin", {}, localStorage);
      this.$router.push({
        path: "/doctorLogin",
      });
    },
    goToPersonalInfo() {
      this.$router.push({
        path: "/personalInfo",
        query: {
          id: this.data.id,
          from: this.$route.path,
        },
      });
    },
  },
};
</script>

<style >
.doctorInfo_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.doctorInfo_banner {
  background-image: url("../../../assets/img/banner.png");
  background-size: 100% 100%;
  height: 248px;
  width: 100%;
}
.doctorInfo_info {
  margin: 16px;
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #756767;
}
.doctorInfo_avatar {
  width: 54px;
  height: 54px;
}

.doctorInfo_info_right {
  flex: 1 0 0;
  margin-left: 12px;
  color: #fff;
}
.doctorInfo_info_right_top {
  height: 50px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.doctorInfo_info_right_top .name {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
}
.doctorInfo_info_right_top .department {
  font-size: 12px;
}
.doctorInfo_banner .CustomButton {
  width: 100px;
  height: 20px !important;
  font-size: 14px;
  padding: 0 !important;
  line-height: 20px;
}
.doctorInfo_info_right_bottom {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
}
.doctorInfo_info_right_bottom img {
  width: 16px;
  margin-right: 4px;
}
.doctorInfo_meetInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #ffffff;
  padding: 0 38px;
}

.doctorInfo_main {
  margin: 0 16px;
  margin-top: -60px;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  flex: 1 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.doctorInfo_main .title {
  font-weight: bold;
  font-size: 14px;
  color: #1aa6ec;
  margin-bottom: 10px;
}
.meetItem_container:last-child {
  border: none;
}
.doctorInfo_foot {
  flex: 50px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}
.doctorInfo_foot_btn {
  font-size: 14px;
  color: #606060;
  line-height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.doctorInfo_foot_btn_icon {
  width: 21px;
}
.info_tips {
  color: #ccc;
  font-size: 14px;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>